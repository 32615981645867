import { useAuthStore } from "~/stores/auth";

export default defineNuxtRouteMiddleware((to, from) => {
    const { user } = useAuthStore();


    if (!user) {
        return navigateTo('/auth/login');
    }

    // if (!user.registered) {
    //     return navigateTo('/auth/register');
    // }
});